<template>
  <div class="client-content-padding">
    <div class="definitions">
      <div class="definition-header">
        <h3 id="root-title">
          {{ rootTitle }}
        </h3>
        <div v-if="modules.length" class="references-container">
          <div class="reference" v-for="(ref, id) in modules" :key="id">
            <a :href="getModuleLink(ref._id)">
              {{ ref.title }}
            </a>
          </div>
        </div>
      </div>
      <div class="definition-content">
        <h2 id="definition-title">
          {{ card.content_0 }}
        </h2>
        <p id="definition-text" :class="stateClass">
          {{ card.content_1 }}
        </p>
      </div>
      <button v-if="btnText.length" @click="next" class="content-btn">
        {{ btnText }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Definition",
  data() {
    return {
      state: 0,
      values: {
        related: this.$route.params.module_id,
        kind: 1,
      },
      card: {
        sibling: {
          left: "root",
        },
        content_0: "",
        content_1: "",
      },
      modules: [],
      used: [],
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    stateClass() {
      if (this.state == 0) return "hidden";
      else return "show";
    },
    kind() {
      if (this.$route.name === "module-definitions") return 1;

      return 0;
    },
    rootTitle() {
      if (this.card.related) {
        const indexOfModule = this.modules.findIndex(
          (module) => module._id == this.card.related
        );

        if (indexOfModule > -1) return this.modules[indexOfModule].title;
      }

      return "";
    },
    btnText() {
      if (this.state == 0 && this.card.content_0) return this.labels.reveal;

      if (this.state != 0 && this.card.sibling?.right) return this.labels.next;

      return "";
    },
  },
  methods: {
    ...mapActions("account", ["getCard"]),
    ...mapActions("notification", ["addNotification"]),
    next() {
      if (this.state == 0) this.state = 1;
      else {
        this.loadCard();
      }
    },
    loadCard() {
      let left = this.card.sibling.left;

      if (this.card.sibling.right) left = this.card._id;

      this.getCard({
        related: this.$route.params.module_id,
        kind: this.kind,
        left: left,
      })
        .then((res) => {
          if (res.success) {
            this.card = res.data.card;
            this.modules = res.data.modules;
            this.used = res.data.used;
          }

          if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
        })
        .catch((err) => console.log(err));
    },
    getModuleLink(id) {
      return this.$router.resolve({
        name: "bundle-module",
        params: {
          monetization_bundle_id: this.$route.params.monetization_bundle_id,
          module_id: id,
        },
      }).href;
    },
  },
  mounted() {
    this.loadCard();
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap");
@import "@/styles/variables";

.content-btn {
  font-family: Montserrat;
  width: 100%;
  font-weight: 600;
  outline: none;
  font-size: 16px;
  color: $white;
  border-radius: 5px;
  background: $focus_color;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.13);
  transition: all 300ms ease-out;
  border: 2px solid transparent;
  text-decoration: none;
  min-width: 130px;
  padding: 10px;
  text-align: center;
  margin-top: 15px;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-content-padding {
  padding-bottom: 30px;
  .definitions {
    .definition-header {
      h3 {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 24px;
        color: #9d9d9d;
        margin-bottom: 0px;
      }
      .references-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 15px 0;
        padding: 15px 0;
        border-top: 1px solid $focus_color;
        border-bottom: 1px solid $focus_color;

        .reference {
          padding: 5px 10px;
          border: 1px solid $focus_color;
          border-radius: 15px;
          margin-right: 15px;
          &:hover {
            background: $focus_color;
            a {
              color: white;
            }
          }
          a {
            color: $focus_color;
            text-decoration: none;
          }
        }
      }
    }
    .definition-content {
      margin-bottom: 40px;

      h2#definition-title {
        padding: 15px 0;
        font-size: 2.5rem;
        color: #553d95;
        margin-bottom: 0px;
        font-family: Montserrat;
        font-weight: bold;
      }

      p#definition-text {
        font-family: Montserrat;
        font-weight: normal;
        font-size: 16px;
        color: $black;
        margin-bottom: 0;
        &.hidden {
          color: transparent;
          text-shadow: 0 0 8px $black;
          user-select: none;
          cursor: default;
        }
      }
    }
  }
}
</style>
